// extracted by mini-css-extract-plugin
export var img = "moreBenefitsRefinancing-module--img--+bC0I";
export var item = "moreBenefitsRefinancing-module--item--dfLyv";
export var item1 = "moreBenefitsRefinancing-module--item1--a4cct";
export var item2 = "moreBenefitsRefinancing-module--item2--OHNYl";
export var item3 = "moreBenefitsRefinancing-module--item3--ExjAh";
export var item4 = "moreBenefitsRefinancing-module--item4--tveqw";
export var itemText = "moreBenefitsRefinancing-module--itemText--joPyS";
export var kameleoonBlock = "moreBenefitsRefinancing-module--kameleoonBlock--n5rUa";
export var kameleoonBtn = "moreBenefitsRefinancing-module--kameleoonBtn--E+eA9";
export var kameleoonTitle = "moreBenefitsRefinancing-module--kameleoonTitle--gI9LN";
export var section = "moreBenefitsRefinancing-module--section--2WW0q";
export var title = "moreBenefitsRefinancing-module--title--mMcmX";
export var wrap = "moreBenefitsRefinancing-module--wrap--zF7eh";
export var wrapper = "moreBenefitsRefinancing-module--wrapper---Yoqt";