import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import clsx from "clsx"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import CardsImg from "./CardsImg"
import ExtinguisherImg from "./ExtinguisherImg"
import CarImg from "./CarImg"
import CaseImg from "./CaseImg"

import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import * as styles from "./moreBenefitsRefinancing.module.scss"

type MoreBenefitsRedisignProps = {
  additionalEventInDataLayer: boolean
  bottomBlock?: boolean
  orderNum?: string
}

export const MoreBenefitsRefinancing = ({
  additionalEventInDataLayer = false,
  bottomBlock = true,
  orderNum,
}: MoreBenefitsRedisignProps) => {
  const handleClick = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayer({
        event: "buttonClick",
        name: "bottom_cta_desyatka",
      })
    }
    scrollToPersonalForm()
  }

  return (
    <section className={styles.section} id="moreBenefits" data-exclude={orderNum}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Преимущества <br /> рефинансирования с Халвой
          </div>
          <div className={styles.wrap}>
            <div className={clsx(styles.item, styles.item1)}>
              <div className={styles.itemText}>
                <div>
                  Рефинансирование
                  <br />
                  кредитных карт
                  <br />
                  <span> любых банков </span>
                </div>
              </div>
              <CardsImg alt="Cards" className={styles.img} />
            </div>
            <div className={clsx(styles.item, styles.item2)}>
              <div className={styles.itemText}>
                <div>
                  <span>Просто и быстро</span> погасим
                  <br />
                  задолженности
                  <br />
                  за вас
                </div>
              </div>
              <div className={styles.img}>
                <ExtinguisherImg alt="Extinguisher" />
              </div>
            </div>
            <div className={clsx(styles.item, styles.item3)}>
              <div className={styles.itemText}>
                <div>
                  <span> Дистанционное оформление</span> <br /> и бесплатная доставка Халвы
                </div>
              </div>
              <CarImg alt="Cashback" className={styles.img} />
            </div>
            <div className={clsx(styles.item, styles.item4)}>
              <div className={styles.itemText}>
                <div>
                  Возможность
                  <span>
                    {" "}
                    досрочного
                    <br /> погашения
                  </span>{" "}
                  без комиссий
                </div>
              </div>
              <div className={styles.img}>
                <CaseImg alt="Case" />
              </div>
            </div>
          </div>
        </div>
        {bottomBlock && (
          <div className={styles.kameleoonBlock}>
            <div className={styles.kameleoonTitle}>
              Оформите Халву и получите <br /> <span> месяц подписки в подарок </span>
            </div>
            <Button className={styles.kameleoonBtn} onClick={handleClick}>
              Оформить карту
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
